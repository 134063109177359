// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();

// START moost-app-theme
$moost-md-green: (
  50: #e6f7ec,
  100 : #c0eccf,
  200 : #96dfb0,
  300 : #6cd290,
  400 : #4dc878,
  500 : #2dbe60,
  600 : #28b858,
  700 : #22af4e,
  800 : #1ca744,
  900 : #119933,
  A100 : #caffd6,
  A200 : #97ffae,
  A400 : #64ff86,
  A700 : #4bff72,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$moost-md-orange: (
  50 : #fff4e8,
  100 : #ffe3c5,
  200 : #ffd09e,
  300 : #ffbd77,
  400 : #ffae59,
  500 : #ffa03c,
  600 : #ff9836,
  700 : #ff8e2e,
  800 : #ff8427,
  900 : #ff731a,
  A100 : #ffffff,
  A200 : #fffefe,
  A400 : #ffdecb,
  A700 : #ffcdb2,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$moost-md-blue: (
  50 : #e6f0f4,
  100 : #bfdbe4,
  200 : #95c3d2,
  300 : #6baabf,
  400 : #4b98b2,
  500 : #2b86a4,
  600 : #267e9c,
  700 : #207392,
  800 : #1a6989,
  900 : #105678,
  A100 : #ace1ff,
  A200 : #79cfff,
  A400 : #46bdff,
  A700 : #2cb4ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$moost-md-pink: (
  50 : #fbe7ef,
  100 : #f4c2d8,
  200 : #ed9abe,
  300 : #e571a4,
  400 : #e05291,
  500 : #da347d,
  600 : #d62f75,
  700 : #d0276a,
  800 : #cb2160,
  900 : #c2154d,
  A100 : #fff3f7,
  A200 : #ffc0d2,
  A400 : #ff8dae,
  A700 : #ff749c,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$moost-md-gray: (
  50 : #ededed,
  100 : #d1d1d1,
  200 : #b3b3b3,
  300 : #949494,
  400 : #7d7d7d,
  500 : #666666,
  600 : #5e5e5e,
  700 : #535353,
  800 : #494949,
  900 : #383838,
  A100 : #f49c9c,
  A200 : #ef6e6e,
  A400 : #ff2a2a,
  A700 : #ff1111,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

.infoBox {
  margin: 5px;
  padding: 3px 15px;
  border: 1px solid green;
  border-radius: 5px;
  color: green;
  font-weight: bold;
}

.errorBox {
  margin: 5px;
  padding: 3px 15px;
  border: 1px solid red;
  border-radius: 5px;
  color: red;
  font-weight: bold;
}

// END moost-app-theme

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$fe-rp-admin-primary: mat.m2-define-palette($moost-md-green);
$fe-rp-admin-accent: mat.m2-define-palette($moost-md-orange);

// The warn palette is optional (defaults to red).
$fe-rp-admin-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$fe-rp-admin-theme: mat.m2-define-light-theme((
  color: (
    primary: $fe-rp-admin-primary,
    accent: $fe-rp-admin-accent
  ),
  typography: mat.m2-define-legacy-typography-config(),
  density: 0,
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($fe-rp-admin-theme);

/* You can add global styles to this file, and also import other style files */
html, body {
  height: 100%;
  width: 100%;
  margin: 0;
}

body, p {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

h1 {
  position: relative;
  font-size: 24px !important;
  font-weight: 300 !important;
}

h2 {
  position: relative;
  color: #707070;
  font-weight: lighter !important;
}

h3 {
  position: relative;
  color: #707070;
  font-weight: lighter !important;
}

h1.title:before, h2.title:before, h3.title:before {
  position: absolute;
  left: 0;
  top: 1.3em;
  height: 0;
  width: 60px;
  content: '';
  border-top: 2px solid #2dbe60;
}

mat-sidenav-content {
  background-color: #FFFFFF;
}

.page-content {
  padding: 24px;
  background-color: #FFFFFF;
  height: 100%;
}

.spinner-container {
  width: 100%;

  mat-spinner {
    margin: auto;
  }
}

.multiline {
  white-space: pre-line !important;
}
